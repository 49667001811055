<template>
  <div id="app">
		<router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
	data(){
		return{
			
		}
	}
}
</script>

<style lang="less">
html,body,header,h3,span,p,i{
	margin: 0;
	padding: 0;
}
</style>
